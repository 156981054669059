<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card class="rounded-lg fill-height pa-6" flat>
      <div class="d-flex mb-4">
        <div class="text-h6 overtext--text">
          <span> Realize o pagamento e envie seu comprovante </span>
        </div>

        <v-spacer />

        <v-icon @click="close()">mdi-close</v-icon>
      </div>

      <v-form v-model="valid" ref="form">
        <v-window v-model="step">
          <v-window-item :value="1">
            <custom-money-input
              v-model="form.value"
              ref="value"
              id="value"
              label="Valor"
              outlined
              :customRules="_valueRules"
            />

            <v-checkbox
              v-model="form.hasInstallment"
              class="ma-0"
              label="Parcelar investimento"
            />

            <div v-if="form.hasInstallment">
              <custom-money-input
                v-model="form.entryValue"
                ref="entryValue"
                id="entryValue"
                label="Valor de entrada"
                outlined
                :customRules="_entryValueRules"
              />

              <custom-select
                v-model="form.installment"
                ref="installment"
                label="Parcelas"
                placeholder="Selecione a quantidade de parcelas"
                :items="_installments"
                :disabled="!parseNumber(form.entryValue)"
                itemValue="value"
                itemText="label"
                outlined
              />
            </div>

            <custom-select
              v-model="form.transferAccount"
              ref="transferAccount"
              label="Banco"
              placeholder="Selecione o banco"
              :items="transferAccounts"
              :disabled="
                form.hasInstallment
                  ? !parseNumber(form.entryValue)
                  : !parseNumber(form.value)
              "
              itemValue="id"
              itemText="name"
              outlined
            />
          </v-window-item>

          <v-window-item :value="2">
            <v-btn class="mb-2" plain @click="step = 1">
              <v-icon left>mdi-arrow-left</v-icon> Voltar
            </v-btn>

            <section
              v-if="
                _currentTransferAccount &&
                _currentTransferAccount.name.toUpperCase() === 'PIX'
              "
              class="mb-4"
            >
              <v-card class="pa-6" outlined>
                <div v-if="openPix">
                  <div class="text-center text-body-1 mb-1">
                    Copie o código ou transfira R$
                    {{ form.hasInstallment ? form.entryValue : form.value }}
                    diretamente para esse QR Code.
                  </div>

                  <div class="text-center text-caption mb-2">
                    QR code válido por somente {{ qrCodeTime }} minutos
                  </div>

                  <v-row justify="center">
                    <v-col cols="12" md="6">
                      <v-img class="mb-4" :src="openPix.charge.qrCodeImage" />

                      <div class="d-flex align-center">
                        <input
                          v-model="openPix.brCode"
                          id="code"
                          class="font-weight-regular text-caption pa-3"
                          readonly
                          @click="copyCode()"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </div>

                <loader-content v-else />
              </v-card>
            </section>

            <section v-else>
              <BankCard
                v-if="_currentTransferAccount"
                class="mb-6"
                :text="_currentTransferAccount.description"
                :bank="
                  _currentTransferAccount.name.replace(/\s/g, '-').toLowerCase()
                "
              />
            </section>

            <card-file-input
              v-model="form.transferReceipt"
              class="mb-4"
              title="Anexe seu comprovante de transferencia"
            />
          </v-window-item>
        </v-window>
      </v-form>

      <v-btn
        color="primary2"
        class="rounded-xl secondary--text"
        large
        block
        @click="submit()"
      >
        {{ step === 1 ? "Próxima etapa" : "Enviar deposito" }}
      </v-btn>
    </v-card>

    <loader-hover v-if="loading" />
  </v-dialog>
</template>

<script>
import { request } from "@/services";
import BankCard from "@/components/investment/BankCard.vue";
import { displayAlert, currencyMask, parseNumber } from "@/utils";

export default {
  data() {
    return {
      dialog: false,
      interval: null,
      step: 1,
      loading: false,
      valid: false,
      currencies: [],
      transferAccounts: [],
      form: {
        value: "",
        entryValue: "",
        transferAccount: "",
        hasInstallment: false,
        installment: 1,
        transferReceipt: null,
      },
      openPix: null,
      qrCodeTime: "",
    };
  },

  props: {
    property: {
      type: Object,
      required: true,
    },
  },

  components: {
    BankCard,
  },

  beforeMount() {
    this.getCurrencies();
  },

  watch: {
    ["_currentTransferAccount.name"]: {
      handler(val) {
        if (val && !this.openPix && val.toUpperCase() === "PIX") this.getPix();
      },
      deep: true,
    },

    ["form.value"]: {
      handler() {
        this.clearOpenPix();
      },
      deep: true,
    },

    ["form.hasInstallment"]: {
      handler() {
        this.clearOpenPix();
      },
      deep: true,
    },

    ["form.entryValue"]: {
      handler() {
        this.clearOpenPix();
      },
      deep: true,
    },

    dialog() {
      if (!this.dialog) this.openPix = null;
    },
  },

  computed: {
    _valueRules() {
      return [
        (v) =>
          parseNumber(v) >= this.property.minValue ||
          `Valor mínimo de ${currencyMask(this.property.minValue)} BRL`,
        (v) =>
          parseNumber(v) <= this.property.maxValue ||
          `Valor máximo de ${currencyMask(this.property.maxValue)} BRL`,
      ];
    },

    _entryValueRules() {
      const value = parseNumber(this.form.value);
      const minValue = value * 0.3;

      return [
        (v) =>
          parseNumber(v) >= minValue ||
          `Valor mínimo de ${currencyMask(minValue)} BRL`,
        (v) =>
          parseNumber(v) < value ||
          `Valor deve ser menor que ${this.form.value} BRL`,
      ];
    },

    _currentTransferAccount() {
      return this.transferAccounts.find(
        (el) => String(el.id) === String(this.form.transferAccount)
      );
    },

    _installments() {
      const totalValue = parseNumber(this.form.value);
      const entryValue = parseNumber(this.form.entryValue);
      const diff = totalValue - entryValue;

      if (!totalValue || !entryValue) return [];

      const installments = [];

      for (let i = 2; i <= 36; i++) {
        const payload = {
          value: i,
          label: `x${i} (primeira de R$ ${this.form.entryValue} e ${
            i - 1
          }x de R$ ${currencyMask(diff / (i - 1))})`,
        };

        installments.push(payload);
      }

      return installments;
    },
  },

  methods: {
    async submit() {
      if (this.step === 1) return (this.step = 2);
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          method: "newDeposito",
          moeda: this.currencies[0].id,
          valor: parseNumber(this.form.value),
          valorEntrada: this.form.hasInstallment
            ? parseNumber(this.form.entryValue)
            : "",
          contratoID: this.$route.params.id,
          info: this.form.transferAccount,
          comprovante: this.form.transferReceipt,
          contratoParcelado: this.form.hasInstallment ? "1" : "0",
          numParcelas: this.form.hasInstallment
            ? this.form.installment.toString()
            : "0",
        };

        await request(payload).then(() => {
          this.displayAlert("Pagamento enviado");
          this.$router.push({ path: "/transactions" });
        });
      } catch (err) {
        this.displayAlert(err, 1);
      } finally {
        this.loading = false;
      }
    },

    async getPix() {
      try {
        const value = this.form.hasInstallment
          ? parseNumber(this.form.entryValue)
          : parseNumber(this.form.value);

        if (!value) return;

        const payload = {
          method: "getQrCodePix",
          valor: value,
        };

        console.log("generate qrcode");
        await request(payload).then((res) => {
          this.openPix = res.apiData;
          this.getRemaining(this.openPix.charge.expiresDate);
        });
      } catch (error) {
        console.log(error);
      }
    },

    // get available currencies
    async getCurrencies() {
      try {
        const payload = {
          method: "getMoedas",
        };

        await request(payload).then((res) => {
          this.currencies = res.moedas;
          this.getMethods();
        });
      } catch (err) {
        console.log(err);
      }
    },

    // return methods of payment for currency
    async getMethods() {
      try {
        const payload = {
          method: "getRedeMoeda",
          moedaID: this.currencies[0].id,
        };

        await request(payload).then((res) => {
          this.transferAccounts = res.redes
            .filter((e) => e.avaliable === "1")
            .map((e) => {
              return {
                id: e.id,
                name: e.nomeRede,
                description: e.infoRede,
              };
            });
        });
      } catch (err) {
        console.log(err);
      }
    },

    open() {
      this.dialog = true;

      setTimeout(() => {
        this.startForm();
      });
    },

    startForm() {
      this.$refs.value.handleInput(currencyMask(this.property.minValue));
      this.$refs.transferAccount.handleInput("");
    },

    close() {
      this.dialog = false;
    },

    copyCode() {
      navigator.clipboard.writeText(this.openPix.brCode);
    },

    getRemaining(finishDate) {
      const moment = require("moment");
      moment.locale("pt-BR");

      // Set the date we're counting down to
      finishDate = moment(finishDate).format("YYYY-MM-DD HH:mm:ss");
      var countDownDate = new Date(finishDate).getTime();

      // Update the count down every 1 second
      this.interval = setInterval(() => {
        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for minutes and seconds
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        this.qrCodeTime =
          String(minutes).padStart(2, "0") +
          ":" +
          String(seconds).padStart(2, "0");

        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(this.interval);
          this.clearOpenPix();
        }
      }, 1000);
    },

    clearOpenPix() {
      this.openPix = null;
      this.qrCodeTime = "";

      if (!!this.form.transferAccount) {
        this.form.transferAccount = "";
        this.$refs.transferAccount.handleInput("");
      }
    },

    displayAlert,

    parseNumber,
  },
};
</script>

<style lang="scss" scoped>
input {
  text-align: center;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #bcbdbd;
}

input:focus {
  border: 1px solid #bcbdbd;
}

.bordered {
  border: 0.25rem solid #00cca6;
}
</style>

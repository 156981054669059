<template>
  <v-card class="pa-4 rounded-lg" outlined>
    <div class="d-flex align-center">
      <div class="d-flex flex-column fill-width">
        <div class="text-body-1 overtext--text">
          <span v-html="text" />
        </div>

        <v-divider class="mt-4 mb-3" />

        <div class="pointer" @click="copy()">
          <v-icon color="primary" small left>mdi-content-copy</v-icon>
          <span class="primary--text"> Copiar dados </span>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },

    bank: {
      type: String,
      default: "safra",
    },
  },

  methods: {
    copy() {
      let content = this.text.replaceAll(" <br /> ", " ");

      navigator.clipboard.writeText(content);
    },
  },
};
</script>

<style lang="scss" scoped>
.blur-opacity {
  background-color: #90909050;
}
</style>

<template>
  <v-container class="py-12">
    <div class="mb-6">
      <router-link to="/investment" class="text-decoration-none">
        <v-icon color="primary">mdi-chevron-left</v-icon>
        <span class="font-weight-bold primary--text">
          <span> Voltar a página de UCS para aluguel </span>
        </span>
      </router-link>
    </div>

    <div class="text-h5 font-weight-bold overtext--text mb-4">
      <span>
        {{ property.title }}
      </span>
    </div>

    <div class="text-justify text-body-1 subtext--text mb-5">
      <span>
        {{ property.location }}
      </span>
    </div>

    <v-row>
      <v-col cols="12" md="8" xl="9">
        <v-sheet class="rounded-lg mb-6" color="accent">
          <v-responsive :aspect-ratio="16 / 9">
            <v-carousel
              v-if="property.images.length"
              height="auto"
              cycle
              hide-delimiter-background
              show-arrows-on-hover
            >
              <v-carousel-item v-for="(item, i) in _images" :key="i">
                <v-img class="rounded-lg" :src="item" :aspect-ratio="16 / 9" />
              </v-carousel-item>
            </v-carousel>
          </v-responsive>
        </v-sheet>

        <section>
          <div class="text-h6 font-weight-bold mb-2">
            <span> Descrição </span>
          </div>

          <div class="text-justify text-body-1 subtext--text">
            <span>
              {{ property.description }}
            </span>
          </div>
        </section>

        <v-divider class="my-6" />

        <section>
          <div class="text-h6 font-weight-bold mb-2">
            <span> Recursos </span>
          </div>

          <div
            v-for="(item, i) in property.resources"
            :key="i"
            class="text-body-1 subtext--text"
          >
            {{ item }}
          </div>
        </section>

        <v-divider class="my-6" />

        <section>
          <div class="text-body-2 subtext--text">
            <span>
              Você concorda com os Termos de Uso e Política de Privacidade do
              Earth guard. Ao optar por depositar em uma UCS, você também
              concorda que os agentes da earth guard podem ligar ou enviar
              mensagens de texto para você.
            </span>
          </div>
        </section>
      </v-col>

      <v-col cols="12" md="4" xl="3">
        <v-card class="rounded-lg pa-4 mb-6" outlined>
          <div class="d-flex flex-column">
            <div class="text-body-2 subtext--text">
              <span> Aporte mínimo </span>
            </div>

            <div>
              <span class="primary--text text-h5 font-weight-bold">
                <span> R$ {{ currencyMask(property.minValue, 2, 2) }} </span>
              </span>
            </div>

            <v-divider class="my-4" />

            <v-btn
              color="primary2"
              class="mb-4 text-none rounded-xl secondary--text"
              @click="submit()"
            >
              Contratar agora
            </v-btn>

            <div class="text-justify text-caption subtext--text">
              <span>
                {{ _randomMessage }}
              </span>
            </div>
          </div>
        </v-card>

        <!--  <v-card class="rounded-lg my-6" outlined>
          <div class="d-flex align-center justify-center">
            <div
              class="elfsight-app-f3d75b17-177e-4bcc-9163-aef9d70899d3"
              style="max-width: 100%"
            />
          </div>
        </v-card> -->
      </v-col>
    </v-row>

    <Deposit ref="deposit" :property="property" />
  </v-container>
</template>

<script>
import { currencyMask } from "@/utils";
import Deposit from "@/components/deposit/Deposit.vue";
const moment = require("moment");

export default {
  props: {
    property: {
      required: true,
    },
  },

  components: {
    Deposit,
  },

  beforeMount() {
    const id = this.$route.params.id;

    if (Number(this.property.sharesSold) === 100)
      return this.$router.push({ path: "/investment" });
  },

  mounted() {
    /* const script = document.createElement("script");
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.defer = true;
    script.setAttribute("data-use-service-core", "");
    document.body.appendChild(script); */
  },

  watch: {
    ["$route.params"]() {
      if (this.$route.params.id == 7)
        return this.$router.push({ path: "/investment" });
    },
  },

  computed: {
    _images() {
      return this.property.images.map((el) => this.$store.getters.apiURL + el);
    },

    _randomMessage() {
      const messages = [
        "A melhor época para começar a plantar foi a 20 anos atrás, a segunda é agora.",
        "Podemos escolher o que semear, mas somos obrigados a colher aquilo que plantamos.",
        "A natureza é o único livro que oferece um conteúdo valioso em todas as suas folhas.",
        "Em todas as coisas da natureza existe algo de maravilhoso.",
      ];

      const current = Number(moment().format("mm"));

      if (current <= 15) return messages[0];
      else if (current > 15 <= 30) return messages[1];
      else if (current > 30 <= 45) return messages[2];
      else return messages[3];
    },
  },

  methods: {
    submit() {
      this.$refs.deposit.open();
    },

    currencyMask,
  },
};
</script>

<style></style>
